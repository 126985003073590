import React from 'react';
import './ASCManagement.scss';

class ASCManagement extends React.Component {
  render() {
    return (
      <div>
        <div className='asc-header'>
          <h3 className='sub-heading container'>
            Outsourcing is fast becoming the answer for an increasing number
            of surgery centers who are seeking reliable management and better
            financial metrics - our program delivers both.
          </h3>
        </div>
        <div className='container'>
          <h1 className='asc-title'>Ambulatory Surgery Center Managament</h1>
          <div className='asc-content-container'>
            <div className='asc-content-text'>
              <p>
                There is a multitude of complex and ever changing regulatory, business and
                administrative functions that must be executed in order to ensure a surgery
                center is not only clinically excellent, but compliant and profitable.
                We possess the expertise and business acumen to help your surgery center
                fulfill its stated goals and allow your team to focus on patient care.
                We provide a catalog of services that can be utilized as part of a turnkey
                management service or services that meet the precise needs of your facility.
              </p>
              <p>
                Unlike other ASC management companies, Prologics can provide services
                to your ASC on a fee for service basis without sacrificing ownership
                in your facility. With a methodical and transparent approach to
                management, your facility can expect to see greater margins
                and an increased bottom line. Our multi-faceted approach includes
                increasing case volume, introducing new procedures, national
                benchmarking, comprehensive case costing, cyclical payor renegotiations,
                accuracy of billing and collections, comprehensive patient payment
                policies and marketing strategies.
              </p>
            </div>
            <ul className='asc-content-list'>
              <li>Day to Day Management</li>
              <li>CMS and State compliance</li>
              <li>Accreditation Assistance</li>
              <li>Revenue Cycle Management</li>
              <li>Managed Care Contracting</li>
              <li>Physician Recruitment</li>
              <li>Facility Marketing</li>
              <li>Equipment/Supply Control</li>
              <li>Product Line Development</li>
              <li>Financial Management and Accounting</li>
              <li>Human Resources Governance</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default ASCManagement;
